import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.png';
import fondo from '../../assets/fondo1.jpg';

const Login = () => {
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();

  useEffect(() => {
    if (accounts.length > 0) {
      navigate('/home');
    }
  }, [accounts, navigate]);

  const handleLogin = async () => {
    try {
      await instance.loginRedirect();
    } catch (error) {
      console.error('Error al iniciar sesión:', error);
    }
  };

  return (
    <div
      className="min-h-screen flex flex-col items-center justify-center bg-cover bg-center p-6"
      style={{ backgroundImage: `url(${fondo})` }}
    >
      <div className="bg-white shadow-xl rounded-xl p-10 w-full max-w-md text-center bg-opacity-90 backdrop-blur">
        <img src={logo} alt="Logo" className="w-32 mx-auto mb-6" />
        <h1 className="text-2xl font-semibold text-gray-800 mb-2">Bienvenido al Portal Megatech</h1>
        <p className="text-gray-500 mb-6">Inicie sesión con su cuenta de Office 365</p>
        <button
          onClick={handleLogin}
          className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-lg font-semibold transition"
        >
          Iniciar sesión con Microsoft
        </button>
      </div>
    </div>
  );
};

export default Login;
