import React, { useEffect, useState, useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
import { useNavigate } from 'react-router-dom';

const DocumentosSGC = () => {
  const { instance, accounts } = useMsal();
  const [driveId, setDriveId] = useState(null);
  const [items, setItems] = useState([]);
  const [expandedFolders, setExpandedFolders] = useState({});
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [matchedFiles, setMatchedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [searching, setSearching] = useState(false);
  const navigate = useNavigate();

  const siteHostname = 'megatechsa.sharepoint.com';
  const sitePath = '/sites/gcalidad';
  const documentLibrary = 'SGC';

  const fetchTokenAndHeaders = useCallback(async () => {
    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });

      return {
        Authorization: `Bearer ${response.accessToken}`,
        'Content-Type': 'application/json',
      };
    } catch (error) {
      if (error.name === 'InteractionRequiredAuthError') {
        // Forzar interacción si es necesario
        await instance.acquireTokenRedirect(loginRequest);
      } else {
        console.error('Error al obtener el token:', error);
        throw error;
      }
    }
  }, [instance, accounts]);

  useEffect(() => {
    const fetchRoot = async () => {
      setLoading(true);
      try {
        const headers = await fetchTokenAndHeaders();

        const siteRes = await fetch(`https://graph.microsoft.com/v1.0/sites/${siteHostname}:${sitePath}`, { headers });
        const siteData = await siteRes.json();

        const drivesRes = await fetch(`https://graph.microsoft.com/v1.0/sites/${siteData.id}/drives`, { headers });
        const drivesData = await drivesRes.json();
        const sgcDrive = drivesData.value.find(d => d.name === documentLibrary);

        setDriveId(sgcDrive.id);

        const filesRes = await fetch(`https://graph.microsoft.com/v1.0/drives/${sgcDrive.id}/root/children`, { headers });
        const filesData = await filesRes.json();

        setItems(filesData.value);
      } catch (err) {
        console.error('Error al cargar documentos:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchRoot();
  }, [fetchTokenAndHeaders]);

  const toggleFolder = async (itemId) => {
    const isOpen = expandedFolders[itemId];

    if (isOpen) {
      setExpandedFolders(prev => {
        const updated = { ...prev };
        delete updated[itemId];
        return updated;
      });
    } else {
      try {
        const headers = await fetchTokenAndHeaders();
        const res = await fetch(`https://graph.microsoft.com/v1.0/drives/${driveId}/items/${itemId}/children`, { headers });
        const data = await res.json();

        setExpandedFolders(prev => ({
          ...prev,
          [itemId]: data.value
        }));
      } catch (err) {
        console.error('Error al abrir subcarpeta:', err);
      }
    }
  };

  const renderItems = (itemsList) => {
    return itemsList.map(item => (
      <li key={item.id} className="ml-4">
        {item.folder ? (
          <div>
            <button
              onClick={() => toggleFolder(item.id)}
              className="text-blue-600 hover:underline flex items-center gap-1"
            >
              <span className="text-yellow-500">📁</span> {item.name}
            </button>
            {expandedFolders[item.id] && (
              <ul className="ml-4 mt-1 border-l border-gray-200 pl-4">
                {renderItems(expandedFolders[item.id])}
              </ul>
            )}
          </div>
        ) : (
          <div className="flex items-center gap-2">
            <button
              onClick={() => handlePreview(item)}
              className="text-gray-700 hover:underline flex items-center gap-1"
            >
              <span className="text-gray-400">📄</span> {item.name}
            </button>
            <a
              href={item['@microsoft.graph.downloadUrl']}
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-blue-500 hover:underline"
            >
              Descargar
            </a>
          </div>
        )}
      </li>
    ));
  };

  const buscarEnDrive = useCallback(async () => {
    if (search.length < 3 || !driveId) {
      setMatchedFiles([]);
      return;
    }

    setSearching(true);
    try {
      const headers = await fetchTokenAndHeaders();
      const sanitizedSearch = encodeURIComponent(search);

      const res = await fetch(
        `https://graph.microsoft.com/v1.0/drives/${driveId}/root/search(q='${sanitizedSearch}')`,
        { headers }
      );

      const data = await res.json();
      const results = data.value || [];

      setMatchedFiles(
        results.filter(
          item =>
            !item.folder &&
            item.name.toLowerCase().includes(search.toLowerCase())
        )
      );
    } catch (err) {
      console.error('Error al buscar en drive:', err);
    } finally {
      setSearching(false);
    }
  }, [search, driveId, fetchTokenAndHeaders]);

  useEffect(() => {
    const delay = setTimeout(() => {
      buscarEnDrive();
    }, 500);
    return () => clearTimeout(delay);
  }, [buscarEnDrive]);

  const handlePreview = async (item) => {
    setSelectedFile(item);
    setPreviewUrl(null);

    try {
      const headers = await fetchTokenAndHeaders();

      const res = await fetch(
        `https://graph.microsoft.com/v1.0/drives/${driveId}/items/${item.id}/preview`,
        {
          method: 'POST',
          headers
        }
      );

      const data = await res.json();
      if (data?.getUrl) {
        setPreviewUrl(data.getUrl);
      }
    } catch (err) {
      console.error('Error al obtener vista previa:', err);
    }
  };

  return (
    <div className={`relative p-6 max-w-7xl mx-auto bg-white shadow-md rounded-lg z-10 ${selectedFile ? 'md:mr-[60%]' : ''}`}>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-3xl font-bold text-gray-800 flex items-center gap-2">
          <span className="text-yellow-500">📁</span>
          Documentos SGC
        </h2>
        <button
          onClick={() => navigate('/')}
          className="text-sm bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Volver al Home
        </button>
      </div>

      <input
        type="text"
        placeholder="🔍 Buscar archivos en SGC (mínimo 3 letras)..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className="border border-gray-300 rounded-lg px-4 py-2 w-full max-w-md mb-6 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
      />

      {loading ? (
        <p className="text-gray-500">Cargando documentos del Sharepoint, aguarde un instante...</p>
      ) : (
        <ul className="space-y-2 text-sm">
          {renderItems(items)}
        </ul>
      )}

      {search.length >= 3 && (
        <div className="mt-8">
          <h3 className="text-lg font-semibold mb-2 text-gray-700 flex items-center gap-1">
            📦 Resultados en SGC
          </h3>
          {searching ? (
            <p className="text-gray-500">Buscando...</p>
          ) : matchedFiles.length === 0 ? (
            <p className="text-gray-400">Sin resultados.</p>
          ) : (
            <ul className="list-disc list-inside text-sm">
              {matchedFiles.map(item => (
                <li key={item.id} className="flex items-center gap-2">
                  <button
                    onClick={() => handlePreview(item)}
                    className="text-blue-600 hover:underline"
                  >
                    📄 {item.name}
                  </button>
                  <a
                    href={item['@microsoft.graph.downloadUrl']}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm text-blue-500 hover:underline"
                  >
                    Descargar
                  </a>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}

      {selectedFile && (
        <div className="fixed top-0 right-0 w-full md:w-3/5 h-full bg-white border-l shadow-xl p-6 z-50 overflow-auto">
          <div className="flex justify-between items-center mb-4">
            <h4 className="text-lg font-semibold truncate text-gray-800">{selectedFile.name}</h4>
            <button
              onClick={() => {
                setSelectedFile(null);
                setPreviewUrl(null);
              }}
              className="text-red-600 font-bold text-lg"
              title="Cerrar"
            >
              ✖
            </button>
          </div>

          {!previewUrl ? (
            <p className="text-gray-500">Cargando vista previa...</p>
          ) : (
            <iframe
              src={previewUrl}
              title="Vista previa"
              className="w-full h-[90vh] border rounded"
            ></iframe>
          )}
        </div>
      )}
    </div>
  );
};

export default DocumentosSGC;