import React from 'react';
import {
  EnvelopeIcon,
  DocumentTextIcon,
  MapPinIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/react/24/outline';
import Recurso1 from '../../assets/Recurso1.png';
import Recurso2 from '../../assets/Recurso2.png';
import { useMsal } from '@azure/msal-react';

const features = [
  {
    title: 'Office 365',
    icon: EnvelopeIcon,
    color: 'bg-blue-100 text-blue-600',
    href: 'https://outlook.office.com',
  },
  {
    title: 'Documentos SGC',
    icon: DocumentTextIcon,
    color: 'bg-indigo-100 text-gray-600',
    href: '/documentos',
  },
  {
    title: 'Work4U',
    icon: MapPinIcon,
    color: 'bg-indigo-100 text-indigo-600',
    href: 'https://work4u.apps4u.io/Default2.aspx',
  },
  {
    title: 'Soporte Interno',
    icon: WrenchScrewdriverIcon,
    color: 'bg-yellow-100 text-yellow-600',
    href: 'https://soporte.megatech.la/index.php?redirect=%2Ffront%2Fticket.form.php&error=3',
  },
];

const Home = () => {
  const { accounts, instance } = useMsal();
  const name = accounts[0]?.name || 'Invitado';

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  return (
    <div
      className="min-h-screen flex flex-col justify-center items-center bg-cover bg-center py-10 px-4 sm:px-8 relative"
      style={{ backgroundImage: `url(${Recurso1})` }}
    >
      <img src={Recurso2} alt="Logo Megatech" className="h-12 absolute top-4 left-4" /> {/* Posiciona el logo en la esquina superior izquierda */}
      <div className="absolute top-4 right-4 bg-black bg-opacity-40 text-white px-4 py-2 rounded-full flex items-center space-x-2"> {/* Posiciona el saludo en la esquina superior derecha */}
        <span>Hola <strong>{name}</strong>!</span>
        <button
          onClick={handleLogout}
          className="ml-2 text-white hover:text-red-300 transition"
          title="Cerrar sesión"
        >
          ↩️
        </button>
      </div>
      <div className="max-w-6xl mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8"> {/* Ajusta el número de columnas y el espacio entre tarjetas */}
          {features.map((item) => (
            <a
              key={item.title}
              href={item.href}
              target={item.href.startsWith('http') ? '_blank' : '_self'}
              rel="noreferrer"
              className="group border border-gray-200 rounded-2xl p-10 shadow hover:shadow-xl transition bg-white bg-opacity-90 hover:bg-opacity-100 flex flex-col items-center text-center" 
            >
              <div className={`w-20 h-20 flex items-center justify-center rounded-full mb-6 ${item.color}`}> {/* Aumenta el tamaño del icono */}
                <item.icon className="w-10 h-10" /> {/* Aumenta el tamaño del icono */}
              </div>
              <h2 className="text-2xl font-semibold text-gray-700 group-hover:text-black"> {/* Aumenta el tamaño del texto */}
                {item.title}
              </h2>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
