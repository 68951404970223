import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import Login from './assets/pages/Login';
import DocumentosSGC from './assets/pages/DocumentosSGC';
import Home from './assets/pages/Home';
import EnDesuso from './assets/pages/EnDesuso';

const App = () => {
  const { instance } = useMsal();
  let accounts = [];
  try {
    accounts = instance.getAllAccounts();
  } catch (error) {
    console.error('Error al obtener cuentas:', error);
  }
  const isAuthenticated = accounts.length > 0;

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/documentos"
          element={
            isAuthenticated ? <DocumentosSGC /> : <Navigate to="/login" replace />
          }
        />
        <Route
          path="/home"
          element={
            isAuthenticated ? <Home /> : <Navigate to="/login" replace />
          }
        />
        <Route
          path="/"
          element={<Navigate to={isAuthenticated ? "/home" : "/login"} replace />}
        />
        <Route 
          path="/en-desuso" 
          element={<EnDesuso />} 
        />
      </Routes>
    </Router>
  );
};

export default App;
