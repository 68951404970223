import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import NotFoundImg from '../../assets/404.jpg';

const EnDesuso = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-gray-100 flex flex-col justify-center items-center px-4">
      <div className="text-center max-w-xl">
        <img
          src={NotFoundImg}
          alt="En desuso"
          className="w-full max-w-md mx-auto mb-8 rounded-xl shadow"
        />
        <h1 className="text-3xl font-bold text-gray-800 mb-4">Sección en desuso</h1>
        <p className="text-gray-600 mb-6">
          Esta sección ya no está disponible o ha quedado fuera de uso. Si creés que esto es un error,
          contactá con el equipo de soporte o volvé al portal principal.
        </p>
        <button
          onClick={() => navigate('/home')}
          className="inline-flex items-center bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-lg transition"
        >
          <ArrowLeftIcon className="w-5 h-5 mr-2" /> Volver al portal
        </button>
      </div>
    </div>
  );
};

export default EnDesuso;